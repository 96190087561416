.myMapContainer {
  margin-right: -220px;
}

#myWholeContentDiv {
  display: flex;
  flex-direction: row;
}
#leftSider {
  display: flex;
  flex-direction: column;
}
#leftSiderViewEdails {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  max-height: 90vh; /* Set maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}
#leftSiderViewEdails::-webkit-scrollbar {
  width: 2px;
}

#leftSiderViewEdails::-webkit-scrollbar-track {
  background: transparent; /* Background color of the track */
}

#leftSiderViewEdails::-webkit-scrollbar-thumb {
  background: #11a761; /* Color of the scrollbar thumb */
}
.MyTopLogo {
  width: 30%;
}
#myHeader {
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.searbox {
  width: 60%;
}
#leftInner {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.ripple-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add any additional styling here if needed */
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(12, 94, 218, 0.658);
  position: relative;
}

.ripple {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: rgb(4, 8, 202);

  animation-delay: 1.5s; /* Delay the start of the animation by 1.5s */
}

.digit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Ensure the digit is visible */
  font-size: 14px; /* Adjust font size as needed */
}
#MobileMap {
  display: none;
}

.MyMobMapAnt {
  height: 100vh;
  width: 100%;
  padding: 0px 0px;
  margin: 0px 0px;
}
#LaptopMap {
  display: block;
}
#map {
  height: 100vh;
  width: 100%;
  padding: 0px 0px;
  margin: 0px 0px;
}
#myFilterIcon {
  display: none;
}

@media only screen and (max-width: 1800px) {
  .myMapContainer {
    margin-right: -580px;
  }
}
@media only screen and (max-width: 1600px) {
  .myMapContainer {
    margin-right: -220px;
  }
}
@media only screen and (max-width: 1200px) {
  #myWholeContentDiv {
    flex-direction: column;
  }
  #myWholeContentDiv {
    flex-direction: column-reverse;
  }
  .myMapContainer {
    margin-right: -150px;
  }
  /* #LaptopMap {
    display: none;
  }
  #MobileMap {
    display: block;
  }
  #leftSider {
    width: 100%;
  } */
}

@media only screen and (max-width: 1105px) {
  .MyMobMapAnt {
    margin-right: -628px;
  }
}
@media only screen and (max-width: 1011px) {
  .myMapContainer {
    margin-right: -50px;
  }
}
@media only screen and (max-width: 900px) {
  .col-lg-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .myMapContainer {
    margin-right: 0px;
  }
  #LaptopMap {
    margin-right: -119px;
    width: auto;
  }

  #map {
    height: 40vh;
  }
  .MyTopLogo {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  #leftInner {
    justify-content: space-between;
  }
  #myFilterIcon {
    display: block;
  }
  #leftInner {
    flex-direction: column;
  }
  .searbox {
    width: 100%;
  }
  #LaptopMap {
    margin-right: -19px;
    width: auto;
  }
}
@media only screen and (max-width: 490px) {
  #map {
    height: 74vh;
  }
  #LaptopMap {
    margin-right: 0px;
    width: auto;
  }
}
