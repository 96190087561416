#smallCorrrierCards {
  display: flex;
  flex-direction: row;
  height: 60px;
}
#MyTopInforCorrierDevForContents {
  width: 310px;
}
#smallCorrrierCards input[type="checkbox"]:checked {
  background-color: rgb(
    53,
    182,
    53
  ) !important; /* Add !important to override Bootstrap */
}
#smallCorrrierCards
  .custom-checkbox
  input:checked
  + .custom-control-label::before {
  background-color: rgb(53, 182, 53);
}

#myAllCorrirerCards {
  display: flex;
  flex-direction: column;
  max-height: 90vh; /* Set maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}

#myAllCorrirerCards::-webkit-scrollbar {
  width: 2px;
}

#myAllCorrirerCards::-webkit-scrollbar-track {
  background: transparent; /* Background color of the track */
}

#myAllCorrirerCards::-webkit-scrollbar-thumb {
  background: #11a761; /* Color of the scrollbar thumb */
}

#smallCorrrierCards img {
  width: 40px;
  height: 40px;
  margin: auto;

  border-radius: 50%;
}
#CorrierDetails {
  display: flex;
  flex-direction: column;
}
#CorrierDetails h5 {
  color: rgb(58, 57, 57);
  font-size: 13px;
  font-weight: bold;
  margin-top: 12px;
}
#CorrierDetails p {
  color: gray;
  margin-top: -6px;
  font-size: 12px;
}
@media only screen and (max-width: 900px) {
  #MyTopInforCorrierDevForContents {
    width: 100%;
  }
}
